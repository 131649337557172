import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import get from 'lodash/get'
import map from 'lodash/map'

import Adsense from 'components/adsense'

import './style.scss'

const Post = ({ data, options, defaultImg, location }) => {
  const {
    category,
    tags,
    description,
    title,
    path,
    date,
    image,
    layout,
  } = data.frontmatter
  const { isIndex, adsense } = options
  const html = get(data, 'html')
  const isMore = isIndex && !!html.match('<!--more-->')
  const fluid = get(image, 'childImageSharp.fluid')
  return (
    <>
      {isIndex ? (
        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
          <Link className="card mb-4" to={path}>
            <div
              className="card-img-top bg-white embed-responsive embed-responsive-16by9"
              style={{
                background: `center / contain no-repeat url(${
                  fluid ? fluid.src : defaultImg
                }
              )`,
              }}
            ></div>
            <div className="card-body px-4 px-sm-0">
              <h2 className="mb-3">
                {title
                  .split('<br>')
                  .join('')
                  .split('<br/>')
                  .join('')}
              </h2>
              <footer className="card-footer">
                <div className="d-flex">
                  <div className="date">
                    <time dateTime={date}>{date}</time>
                  </div>
                  <div className="col p-0"></div>
                  {Badges({
                    type: 'card',
                    items: category.split(','),
                    path,
                    primary: true,
                  })}
                </div>
              </footer>
            </div>
          </Link>
        </div>
      ) : (
        <article
          className={`py-5 article ${layout === 'post' ? '' : 'fixedPage'}`}
          key={path}
        >
          <div className="info-wrapper container">
            <div className="info">
              <div className="title-wrapper">
                <h1>
                  <div></div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  ></span>
                  <div></div>
                </h1>
                {layout === 'post' ? <time dateTime={date}>{date}</time> : ''}
              </div>
              {layout === 'post' ? (
                <div className="position-relative">
                  <div className="tags">
                    {Badges({
                      type: 'default',
                      items: category.split(', '),
                      path,
                      primary: true,
                    })}
                    {Badges({ type: 'default', items: tags, path })}
                  </div>
                  <div className="share pb-md-2 pb-0 pt-3 text-right">
                    <a
                      href={`https://twitter.com/share?url=${
                        location.href
                      }&hashtags=Shinonome,${category.split(', ').join(',')}${
                        tags ? ',' + tags.join(',') : ''
                      }&text=${title
                        .split('<br>')
                        .join('')
                        .split('<br/>')
                        .join('')}`}
                      target="_blank"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="twitter-square"
                        className="svg-inline--fa fa-twitter-square fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36px"
                        height="36px"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"
                        />
                      </svg>
                    </a>
                    <a
                      href={`http://www.facebook.com/share.php?u=${location.host +
                        location.pathname}`}
                      target="_blank"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="facebook-square"
                        className="svg-inline--fa fa-facebook-square fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="36px"
                        height="36px"
                      >
                        <path
                          fill="currentColor"
                          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                        />
                      </svg>
                    </a>
                    <a
                      href={`https://social-plugins.line.me/lineit/share?url=${location.href}`}
                      target="_blank"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="line"
                        className="svg-inline--fa fa-line fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="36px"
                        height="36px"
                      >
                        <path
                          fill="currentColor"
                          d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1 0 2.1.5 2.6 1.4l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="content">
              {fluid ? (
                <Img
                  fluid={fluid}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    marginBottom: '24px',
                    borderRadius: '8px',
                  }}
                />
              ) : (
                ''
              )}
              {description ? (
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></p>
              ) : (
                ''
              )}
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: isMore ? getDescription(html) : html,
                }}
              />
            </div>
          </div>
        </article>
      )}
    </>
  )
}

export default Post

const getAd = (isIndex, adsense) => {
  return !isIndex ? <Adsense clientId={adsense} slotId="" format="auto" /> : ''
}

const getDescription = body => {
  body = body.replace(/<blockquote>/g, '<blockquote class="blockquote">')
  if (body.match('<!--more-->')) {
    body = body.split('<!--more-->')
    if (typeof body[0] !== 'undefined') {
      return body[0]
    }
  }
  return body
}

const Button = ({ path, label, primary }) => (
  <Link className="readmore" to={path}>
    <span
      className={`btn btn-outline-primary btn-block ${
        primary ? 'btn-outline-primary' : 'btn-outline-secondary'
      }`}
    >
      {label}
    </span>
  </Link>
)

const Badges = ({ type, items, path, primary }) =>
  map(items, (item, i) => (
    <div className="tag-wrap" key={`${path}_tag_${i}`}>
      <div className={`tag`}>
        {primary ? (type === 'card' ? item : '<' + item + '>') : '#' + item}
      </div>
    </div>
  ))
